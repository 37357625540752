import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RegisterUser, UpdateDeliveryDetails, UpdatePaymentDetails } from "../../infrastructure/api/User/UserAPI";
import { User } from "../../domain/Types/User";
import { TextField, Stepper, Step, StepLabel, Button, MenuItem, Checkbox, FormControlLabel, CircularProgress } from "@mui/material";
import { useForm, Controller, SubmitHandler, FieldValues } from "react-hook-form";
import AppLayout from "../customer/Components/Layouts/App/AppLayout";
import Swal from "sweetalert2";
import { Registration } from "../../domain/Types/Registration";
import { DeliveryDetails } from "../../domain/Types/DeliveryDetails";
import { paymentoption } from "../../domain/Types/paymentoption";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import PersonalDetailsStep from "./GeneralComponents/MultiStepRegister/PersonalDetailsStep";
import PaymentInformation from "./GeneralComponents/MultiStepRegister/PaymentInformation";
import DeliveryInformation from "./GeneralComponents/MultiStepRegister/DeliveryInformation";
import { AxiosError } from "axios";

// TypeScript interface for the summary data
interface SummaryData {
  firstname?: string;
  lastname?: string;
  emailaddress?: string;
  paymentMethod?: string;
  AccountNumber?: string;
  provider?: string;
  mobileNumber?: string;
  latitude?: number;
  longitude?: number;
  useCurrentAddress?: boolean;
  deliveryAddress?: string;
}

// Define schemas for each step
const stepSchemas = [
  z.object({
    firstname: z.string().min(1, "First Name is required"),
    lastname: z.string().min(1, "Last Name is required"),
    emailaddress: z
      .string()
      .email("Invalid email address")
      .min(1, "Email address is required"),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/[^A-Za-z0-9]/, "Password must contain at least one special character"),
    confirmPassword: z.string().min(1, "Confirm Password is required"),
  }).superRefine((data, ctx) => {
    if (data.password !== data.confirmPassword) {
      ctx.addIssue({
        code: "custom",
        path: ["confirmPassword"],
        message: "Passwords do not match",
      });
    }
  }),
  z.object({
    paymentMethod: z.string().min(1, "Payment method is required"),
    provider: z.string().optional(),
    mobileNumber: z
      .string()
      .regex(/^\d{10,12}$/, "Invalid mobile number")
      .optional(),
  }),
  z.object({
    deliveryAddress: z.string().optional(),
    locationEnabled: z.boolean().refine((val) => val === true, {
      message: "Location tracking must be enabled.",
    }),
    useCurrentAddress: z.boolean().optional(),
  }),
  z.object({}),
];  

const Register = () => {
  //const [activeStep, setActiveStep] = useState(() => parseInt(sessionStorage.getItem('activeStep') || '0'));
  const [loading, setLoading] = useState(false); // Loading state
  const [summary, setSummary] = useState<SummaryData>(() => JSON.parse(sessionStorage.getItem('summary') || '{}'));
  const [activeStep, setActiveStep] = useState(0);
  const schema = stepSchemas[activeStep];
  const navigate = useNavigate();
  const win = window.sessionStorage;

  const { control, handleSubmit,trigger, formState: { errors }, reset, setError, watch } = useForm({
    resolver: zodResolver(schema), // Validate based on the current step's schema
    mode: "onChange",
  });

  useEffect(() => {
    sessionStorage.setItem('activeStep', activeStep.toString());
    sessionStorage.setItem('summary', JSON.stringify(summary));
    sessionStorage.setItem('useCurrentAddress', JSON.stringify(summary.useCurrentAddress));

  }, [activeStep, summary]);



const onSubmit = (data: FieldValues) => {
  if (schema) {
    try {
      // Validate the current step's data
      stepSchemas[activeStep].parse(data);

      // If no errors, proceed to the next step or complete registration
      if (activeStep === steps.length - 1) {
        handleFinalSubmit(data); // Handle final submission logic
      } else {
        setSummary(prev => ({ ...prev, ...data })); // Update summary with current step's data
        setActiveStep(activeStep + 1); // Move to the next step
      }
    } catch (err) {
      if (err instanceof z.ZodError) {
        err.errors.forEach(({ path, message }) => {
          setError(path[0] as keyof FieldValues, { type: "manual", message }); // Display error for specific field
        });
      }
    }
  }
  else{
        // Skip validation for Step 4 (Summary)
        console.log('Skipping validation for Step 4');
        // Continue with the process for Step 4
  }
};

  const handleFinalSubmit = async (data: FieldValues) => {
    // Combine all steps' data
    setLoading(true);
    const registrationData = { ...summary, ...data };
    console.log("Final Registration Data:", registrationData);

    const user: User = {
      id: 0,
      business_id: 0,
      firstname: summary.firstname || "",
      lastname: summary.lastname || "",
      email: summary.emailaddress || "",
      email_verified_at: summary.emailaddress || "",
      password: data.password,
      device_token: data.device_token,
      remember_token: "sdseweewe",
      acl: "customer",
      created_at: "12-12-12",
      updated_at: "12-12-12",
    };

    const deliverDetails: DeliveryDetails = {
      id: 1,
      user_id: user.id,
      delivery_address: summary.useCurrentAddress ? summary.deliveryAddress : "Address not found",
      latitude: summary.latitude || 0, // Ensure a default value if latitude is null
      longitude: summary.longitude || 0, // Ensure a default value if longitude is null
    };

    const paymentopt: paymentoption = {
      id: 1,
      user_id: user.id,
      type: summary.paymentMethod || "",
      provider: summary.provider  || "",
      account_number: summary.mobileNumber || "",
      phone_number: summary.mobileNumber || "",
      card_number: "",
      card_expiry: "",
      is_default: 1,
    };

    const register: Registration = {
      user: user,
      paymentOption: "",
      paymentNumber: "",
      deliverDetails: deliverDetails,
    };    

    const UserRegister = async () => {
      try {
        const data = await RegisterUser(register.user); // The user saved save paymentoption and deliverydetails
        paymentopt.user_id = data.id;
        await UpdatePaymentDetails(paymentopt);
        deliverDetails.user_id = data.id;
        await UpdateDeliveryDetails(deliverDetails);

        Swal.fire({
          title: 'SUCCESS',
          text: 'Registration Successful',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });

        win.setItem('Token', data.remember_token);
        win.setItem('userid', data.id.toString());
        win.setItem('name', data.firstname);
        win.setItem('email', data.email);
        win.setItem('userID', data.id.toString());
        setSummary(data);

        sessionStorage.clear(); // Clear session storage after successful registration

        // Navigate to Email Verification Page with state
        navigate("/EmailVerification", {
          state: {
            userId: data.id,
            name: data.firstname,
            email: data.email,
          },
        });
      } catch (error: unknown) {
        console.error('Error registering user:', error);
        if (error instanceof AxiosError) {
          Swal.fire({
            title: "Error",
            text: error.response?.data?.message || "An error occurred. Please try again.",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
            
      }
    finally {
      setLoading(false);
    }
    };

    UserRegister();
    reset();
  };
  
  const handleNext = async () => {
    console.log(summary);
    console.log("Next Triggered");
    const isValid = await trigger(); // Validate the current step
    if (isValid) {
      setActiveStep((prev) => prev + 1); // Advance step only if valid
    } else {
      console.log("Validation failed at step:", activeStep);
    }
  };
  
  const handleBack = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const steps = ["Register", "Payment Option", "Delivery Information", "Summary"];

  return (
    <AppLayout>
      <div className="page-section mb-60 mt-7">
        <div className="container">
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <form onSubmit={handleSubmit(onSubmit)}>
            {activeStep === 0 && (
                <PersonalDetailsStep
                control={control}
                errors={errors}
                summary={summary}  
                watch={watch} 
                setSummary={updatedSummary => {
                  setSummary(updatedSummary);
                }}
              />
            )}

            {activeStep === 1 && (
                <PaymentInformation
                control={control}
                errors={errors}
                summary={summary}  
                watch={watch} 
                setSummary={updatedSummary => {
                  setSummary(updatedSummary);
                }}
              />
            )}

            {activeStep === 2 && (
              <DeliveryInformation 
                control={control}
                errors={errors}
                summary={summary}    
                watch={watch} 
                setSummary={updatedSummary => {
                  setSummary(updatedSummary);
                }}                        
              />
            )}

            {activeStep === 3 && (
              <div>
                <h4 className="login-title">Summary</h4>
                <p><strong>First Name:</strong> {summary.firstname}</p>
                <p><strong>Last Name:</strong> {summary.lastname}</p>
                <p><strong>Email Address:</strong> {summary.emailaddress}</p>
                <p><strong>Payment Method:</strong> {summary.paymentMethod}</p>
                <p><strong>Provider:</strong> {summary.provider}</p>
                <p><strong>Mobile Number:</strong> {summary.mobileNumber}</p>
                <p><strong>Delivery Address:</strong> {summary.useCurrentAddress ? summary.deliveryAddress : "New Address"}</p>
                <p><strong>Latitude:</strong> {summary.latitude}</p>
                <p><strong>Longitude:</strong> {summary.longitude}</p>
              </div>
            )}

          <div className="form-actions">
            {activeStep > 0 && (
              <Button onClick={handleBack}>Back</Button>
            )}
            {activeStep < steps.length - 1 ? (
              // For steps before the last one
              <Button onClick={handleNext} variant="outlined">
                {loading ? <CircularProgress size={24} /> : "Next"}
              </Button>
            ) : (
              // For the final step
              <Button type="submit" variant="outlined">
                {loading ? <CircularProgress size={24} /> : "Register"}
              </Button>
            )}
          </div>
          </form>
        </div>
      </div>
    </AppLayout>
  );
};

export default Register;