import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, push, get, onValue } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

/*const firebaseConfig = {
  apiKey: "AIzaSyDPvnv2F-OAMevjIyO2LkLLE1eVkMXERx8",
  authDomain: "fcmfoodhut.firebaseapp.com",
  databaseURL: "https://fcmfoodhut-default-rtdb.firebaseio.com",
  projectId: "fcmfoodhut",
  storageBucket: "fcmfoodhut.appspot.com",
  messagingSenderId: "450288019274",
  appId: "1:450288019274:web:a617afbd332b1e201003b3",
  measurementId: "G-S3KVLLSCBY"
};*/

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDPvnv2F-OAMevjIyO2LkLLE1eVkMXERx8",
  authDomain: "fcmfoodhut.firebaseapp.com",
  databaseURL: "https://fcmfoodhut-default-rtdb.firebaseio.com",
  projectId: "fcmfoodhut",
  storageBucket: "fcmfoodhut.firebasestorage.app",
  messagingSenderId: "450288019274",
  appId: "1:450288019274:web:a617afbd332b1e201003b3",
  measurementId: "G-S3KVLLSCBY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database
const database = getDatabase(app);

// Initialize FCM
const messaging = getMessaging(app);
const vapidKey = "BAoco6OkZ-HQD8G9sacRvcFF7OW8LYeb8hLl0if0hFZqINbRojdz11uUq6eXf0qxzmxH0UcDLihKeUQ721Sds9M";

// Export services
export { database, ref, set, get, push, onValue, messaging, getToken, onMessage, vapidKey };