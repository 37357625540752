import React, { useEffect, useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller } from "react-hook-form";
import AppRegistration from "../../../../assets/AppRegistration.jpg";

interface PersonalDetailsStepProps {
  control: any;
  errors: any;
  watch: any;
  summary: any;
  setSummary: (updatedSummary: any) => void;
}

const PersonalDetailsStep: React.FC<PersonalDetailsStepProps> = ({
  control,
  errors,
  watch,
  summary,
  setSummary,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  // Synchronize summary with form values
  useEffect(() => {
    const subscription = watch((values: any) => {
      setSummary((prevSummary: any) => ({ ...prevSummary, ...values }));
    });

    return () => subscription.unsubscribe();
  }, [watch, setSummary]);

  return (
    <div style={{ display: "flex", alignItems: "center" , paddingTop: 20 }}>
      {/* Form Section */}
      <div style={{ flex: 1, padding: "2rem" }}>
        <h4 className="login-title">Register</h4>
        <Controller
          name="firstname"
          control={control}
          defaultValue=""
          rules={{ required: "First Name is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="First Name"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.firstname}
              helperText={errors.firstname?.message || ""}
            />
          )}
        />
        <Controller
          name="lastname"
          control={control}
          defaultValue=""
          rules={{ required: "Last Name is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Last Name"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.lastname}
              helperText={errors.lastname?.message || ""}
            />
          )}
        />
        <Controller
          name="emailaddress"
          control={control}
          defaultValue=""
          rules={{
            required: "Email address is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Email Address"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.emailaddress}
              helperText={errors.emailaddress?.message || ""}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: "Password is required",
            minLength: {
              value: 8,
              message: "Password must be at least 8 characters long",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type={showPassword ? "text" : "password"}
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.password}
              helperText={errors.password?.message || ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          defaultValue=""
          rules={{
            validate: (value) =>
              value === control.getValues("password") || "Passwords must match",
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type={showConfirmPassword ? "text" : "password"}
              label="Confirm Password"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message || ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>

      {/* Image Section */}
      <div style={{ flex: 1, padding: "2rem" }}>
        <img
          src={AppRegistration}
          alt="Registration Illustration"
          style={{ width: "100%", height: "auto", borderRadius: "8px" }}
        />
      </div>
    </div>
  );
};

export default PersonalDetailsStep;