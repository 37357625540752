import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ShoppingBadge from "../../CommmonComponents/ShoppingBadge";
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider, Button, Typography, Box } from '@mui/material';
import { Logout } from '../../../../../infrastructure/api/User/UserAPI';
const drawerWidth = 240;

const AdminNavigation = () => {
  const win = window.sessionStorage;
  const navigate = useNavigate();

  const ShowOrder = (orderType: string) => {
    navigate("/AdminOrders", { state: { orderType } });
  };    

  const handleURLNavigate = (path: string) => {
    navigate(path);
  }  

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };  

 /* const handleLogout = () => {
    win.clear();    
    Logout()
    navigate("/");
  };*/

  const handleLogout = async () => {
    try {
      // Clear local data
      win.clear(); // Ensure this function clears all relevant local data
  
      // Attempt to log out via API
      const data = await Logout();
      console.log(data);
      // Navigate to the login page or home page
      navigate("/");      
    } catch (error) {
      console.error("Logout failed:", error);      
    }
  };  

  return (
    <>
        {/* Modal Drawer */}
        <Drawer
          variant="temporary" // Makes the drawer modal-like
          open={drawerOpen}
          onClose={toggleDrawer} // Close the drawer when clicking outside
          ModalProps={{
            keepMounted: true, // Improve performance on mobile
          }}
          sx={{
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
        >
  
          {/* Drawer content */}
          <List>
            <ListItem button onClick={() => handleURLNavigate("/Dashboard")}>
              <ListItemIcon><DashboardIcon /></ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button onClick={() => ShowOrder("NewOrders")}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="New Orders" />
            </ListItem>
            <ListItem button onClick={() => ShowOrder("InProgressOrders")}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="Orders In Progress" />
            </ListItem>
            <ListItem button onClick={() => ShowOrder("CompletedOrders")}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="Orders Completed" />
            </ListItem>                        
            <ListItem button onClick={() => handleURLNavigate("/PaymentMenthods")}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="Payment Methods" />
            </ListItem>
            <ListItem button onClick={() => handleURLNavigate("/Promotions")}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="Promotions Templates" />
            </ListItem>          
            <ListItem button onClick={() => handleURLNavigate("/SchedulePromotions")}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="Schedule Promotions" />
            </ListItem>         
            <ListItem button onClick={() => handleURLNavigate("/BusinessMenus")}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="Menus" />
            </ListItem>                       
            <ListItem button onClick={() => handleURLNavigate("/AdminSettings")}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>     
            <ListItem button onClick={() => handleURLNavigate("/AdminAccount")}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="My Account" />
            </ListItem>                                 
            <ListItem button                   
              onClick={() => {
                win.clear(); // Clear session
                navigate('/'); // Redirect to sign-in
                }}>
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>

        <nav className="navbar navbar-expand-lg">
      <div className="container-fluid" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* Left section with Hamburger Menu and Brand */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Hamburger Menu */}
          <IconButton onClick={toggleDrawer} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          {/* Brand */}
          <Link className="navbar-brand" to="/Dashboard">FoodHut</Link>
        </Box>
        {/* Right section with Welcome text, Account link, and Logout button */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography>
            <span className="font-bold">Welcome:</span> {win.getItem("fname")} {win.getItem("lname")}
          </Typography>
          <Typography><span className="font-bold">|</span></Typography>
          <Button onClick={handleLogout}>Logout</Button>
        </Box>
      </div>
    </nav>
    </>
  );
};

export default AdminNavigation;