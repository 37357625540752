import React, { useEffect } from "react";
import { TextField, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import PaymentIllustration from '../../../../assets/PaymentIllustration.jpg';

interface PaymentInformationProps {
  control: any;
  errors: any;
  watch: any;
  summary: {
    paymentMethod?: string;
    provider?: string;
    mobileNumber?: string;
  };
  setSummary: (updatedSummary: any) => void; // Callback to update the summary
}

const PaymentInformation: React.FC<PaymentInformationProps> = ({
  control,
  errors,
  watch,
  summary,
  setSummary,
}) => {
  // Synchronize summary with form values
  useEffect(() => {
    const subscription = watch((values: any) => {
      setSummary((prevSummary: any) => ({ ...prevSummary, ...values }));
    });

    return () => subscription.unsubscribe();
  }, [watch, setSummary]);

  return (
    <div style={{ display: "flex", alignItems: "center" , paddingTop: 20 }}>
      {/* Form Section */}
      <div style={{ flex: 1, padding: "2rem" }}>
      <h4 className="login-title">Payment Option</h4>

      {/* Payment Method Select */}
      <Controller
        name="paymentMethod"
        control={control}
        defaultValue=""
        rules={{ required: "Payment method is required" }}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Payment Method"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.paymentMethod}
            helperText={errors.paymentMethod?.message || ""}
          >
            <MenuItem value="mobilePayment">Mobile Payment</MenuItem>
          </TextField>
        )}
      />

      {/* Provider Select */}
      <Controller
        name="provider"
        control={control}
        defaultValue=""
        rules={{ required: "Provider is required" }}
        render={({ field }) => (
          <TextField
            {...field}
            select
            label="Provider"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.provider}
            helperText={errors.provider?.message || ""}
          >
            <MenuItem value="AirtelMoney">Airtel Money</MenuItem>
            <MenuItem value="TnmMpamba">TNM Mpamba</MenuItem>
          </TextField>
        )}
      />

      {/* Mobile Number Input */}
      <Controller
        name="mobileNumber"
        control={control}
        defaultValue=""
        rules={{
          required: "Mobile number is required",
          pattern: {
            value: /^[0-9]{9,12}$/,
            message: "Invalid mobile number format",
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Mobile Number"
            variant="outlined"
            fullWidth
            margin="normal"
            type="tel"
            error={!!errors.mobileNumber}
            helperText={errors.mobileNumber?.message || ""}
          />
        )}
      />
    </div>

      {/* Image Section */}
      <div style={{ flex: 1, backgroundImage: `url('https://loremipsum.io/21-of-the-best-placeholder-image-generators')`, backgroundSize: "cover", backgroundPosition: "center", height: "100%" }}>
      <img 
          src={PaymentIllustration} 
          alt="Download Android" 
          style={{ width: '100%', height: 'auto' }} 
          />
      </div>
    </div>    
  );
};

export default PaymentInformation;