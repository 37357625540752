import React, { useEffect, useState } from "react";
import { useForm, Controller, SubmitHandler, FieldValues } from "react-hook-form";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import SpinnerModal from "../SpinnerModal";
import { LoginUser } from "../../../infrastructure/api/User/UserAPI";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { requestFCMToken } from "../../../utilities/firebase";
import { Fcmtoken } from "../../../domain/Types/Fcmtoken";
import { storeTokenData } from "../../../infrastructure/api/FcmToken/TokenFcm";
import { User } from "../../../domain/Types/User";

// Validation schema using Zod
const loginSchema = z.object({
  email: z
    .string()
    .nonempty("Email is required")
    .email("Invalid email address"),
  password: z.string().nonempty("Password is required"),
  remember_me: z.boolean().optional(),
  device_token: z
    .string().optional(), // Validate that the token is not empty
});

const SigninModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  onSignInFeedback: (success: boolean) => void;
}> = ({ open, handleClose, onSignInFeedback }) => {
  const navigate = useNavigate();
  const win = window.sessionStorage;

  const [loginerrors, setErrors] = useState<string[]>([]);
  const [connect, setConnect] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  // Initialize react-hook-form with FieldValues
  const { control, handleSubmit, formState: { errors }, setValue, reset } = useForm<FieldValues>({
    resolver: zodResolver(loginSchema),
    defaultValues: { device_token: "" }, // Default value for device_token
  });

  // Fetch the FCM token when the component mounts and set the value of device_token
  useEffect(() => {
    const fetchFCMToken = async () => {
      const token = await requestFCMToken();
      if (token) {
        setValue("device_token", token); // Set the device token in the form state
      }
    };

    fetchFCMToken();
  }, [setValue]);

    // Log errors on validation failure
    useEffect(() => {
        if (Object.keys(errors).length > 0) {
          console.log("Form Validation Errors:", errors);
        }
      }, [errors]);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setErrors([]);
    setMessage("");
    setIsLoading(true);

    try {
      setIsLoading(true);

      let UserFcmToken: string | null = localStorage.getItem('fcmToken');

      // Generate a new token if not available
      if (!UserFcmToken || UserFcmToken.trim() === '') {
        UserFcmToken = await requestFCMToken();
        if (UserFcmToken) {
          localStorage.setItem('fcmToken', UserFcmToken);
        } else {
          console.error('Failed to generate FCM token');
        }
      }

      data.device_token = UserFcmToken ?? ""; // Use an empty string if UserFcmToken is null

      // Check the rememberMe field and set persistence if true
      if (data.rememberMe) {
        localStorage.setItem('rememberMe', 'true');
      } else {
        localStorage.removeItem('rememberMe');
      }

      const user: User = {
          id: 0,
          business_id: 0,
          firstname: "",
          lastname: "",
          email: data.email,
          email_verified_at: "",
          password: data.password,
          device_token: data.device_token,
          remember_token: "",
          acl: "customer",
          created_at: "12-12-12",
          updated_at: "12-12-12",
      };

      const responseData = await LoginUser(user);

      // Add the token to localStorage (using the key 'remember_token')
      if (responseData.remember_token) {
        localStorage.setItem('Token', responseData.remember_token);
      }
      
      if (UserFcmToken && UserFcmToken.trim() !== '') {
        const UserToken: Fcmtoken = {
          user_id: responseData.id,
          token: UserFcmToken
        };
        storeTokenData(UserToken);
      } else {
        console.error('FCM Token is not available or is empty');
      }

      onSignInFeedback(true);
      switch (responseData.acl) {
        case 'customer':
          win.setItem('Token', responseData.remember_token);
          win.setItem('userid', responseData.id.toString());
          win.setItem('fname', responseData.firstname);
          win.setItem('lname', responseData.lastname);
          win.setItem('email', responseData.email);
          navigate('/');
          break;
        case 'driver':
          win.setItem('Token', responseData.remember_token);
          win.setItem('userid', responseData.id.toString());
          win.setItem('Businessid', responseData.business_id.toString());
          win.setItem('fname', responseData.firstname);
          win.setItem('lname', responseData.lastname);
          win.setItem('email', responseData.email);
          navigate('/Driverhome');
          break;
        case 'Administrator':
          break;
        case 'superadmin':
          win.setItem('Token', responseData.remember_token);
          win.setItem('userid', responseData.id.toString());
          win.setItem('fname', responseData.firstname);
          win.setItem('lname', responseData.lastname);
          win.setItem('email', responseData.email);
          navigate('/FoodhutDashboard');
          break;
        case 'user':
          setMessage('Account Not Active, Please contact Administrator');
          break;
        case 'businessowner':
          win.setItem('Token', responseData.remember_token);
          win.setItem('userid', responseData.id.toString());
          win.setItem('Businessid', responseData.business_id.toString());
          win.setItem('fname', responseData.firstname);
          win.setItem('lname', responseData.lastname);
          win.setItem('email', responseData.email);
          navigate('/Dashboard');
          break;
        default:
          setMessage('Account Not Active, Please contact Administrator');
      }

      Swal.fire({
        title: 'SUCCESS',
        text: 'Login Successful',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500
      });

      handleClose();

    } catch (error: any) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 401) {
        Swal.fire({
          title: 'Credentials Do not Match',
          text: 'Please verify your Credentials and try again.',
          icon: 'info',
          showConfirmButton: true
        });
      } else {
        Swal.fire({
          title: 'Unable to Login',
          text: 'Please Click Forgot Password to Reset',
          icon: 'info',
          showConfirmButton: true
        });
      }
      onSignInFeedback(false);
    } finally {
      setIsLoading(false);
      reset();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-react-Form">
            <span className="text-red-500">{loginerrors}{message}</span>

            {/* Email Input */}
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email Address"
                  fullWidth
                  margin="normal"
                  error={!!errors.email}
                  helperText={errors.email && "Email is required"}
                />
              )}
            />

            {/* Password Input */}
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  fullWidth
                  margin="normal"
                  error={!!errors.password}
                  helperText={errors.password && "Password is required"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            {/* Remember Me Checkbox */}
            <div className="check-box d-inline-block ml-0 ml-md-2 mt-10">
              <Controller
                name="rememberMe"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <div className="check-box d-inline-block ml-0 ml-md-2 mt-10">
                    <input
                      {...field}
                      type="checkbox"
                      id="rememberMe"
                      checked={field.value ?? false}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                    <label htmlFor="rememberMe">Remember me</label>
                  </div>
                )}
              />
            </div>

            {/* Forgot Password and Register Links */}
            <div className="col-md-8 mt-10 mb-20 text-left text-md-right">
              <Link to={`/ForgotPassword`}>
                <span className="ForgotPassword">Forgotten password?</span>
              </Link><br />
              <Link to={`/Register`}>
                <span className="Register">Register {!!errors}</span>
              </Link>
            </div>

            {/* Submit Button */}
            <div className="col-md-12">
              <Button type="submit" className="Loginbutton mt-0" variant="contained" disabled={isLoading}>
                {isLoading ? <CircularProgress size={24} /> : "Login"}
              </Button>
            </div>
          </div>
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>

      <SpinnerModal isLoading={isLoading} message={connect} />
    </Dialog>
  );
};

export default SigninModal;