import React from 'react';

const PromotionPage: React.FC = () => {
  return (
    <div

    >
      <img
        src="https://app.foodhut.mw/Admin/public/images/starlightflyer.jpg"
        alt="Promotion"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
    </div>
  );
};

export default PromotionPage;