import { useState, useEffect } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 
import Slider from "react-slick";
import { FetchMenuCategories } from '../../../../infrastructure/api/Menus/MenuAPI'; 
import { MenuCategory } from '../../../../domain/Types/MenuCategory'; 

type CategoryWithIcon = MenuCategory & {
  label: string;
  icon: string;
};

// Icon mapping
const categoryIcons: { [key: string]: string } = {
  Wraps: '🌯',
  Burgers: '🍔',
  Meals: '🍽️',
  Sides: '🍟',
  Sandwiches: '🥪',
  Drinks: '🥤',
  Breakfast: '🍳',
  Salads: '🥗',
  Starters: '🍢',
  Pasta: '🍝',
  'Fish & Chips': '🍟',
  'Signature Dishes': '🌟',
  'OFF-THE-GRILL': '🔥',
  DESSERTS: '🍰',
  'SOMETHING LITE': '🥗',
  TACOS: '🌮',
  QUESADILLAS: '🌮',
  PIZZAS: '🍕',
  BURRITOS: '🌯',
  SAMOSA: '🥟',
};

const CategorySection = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<CategoryWithIcon[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data: MenuCategory[] = await FetchMenuCategories();
        const mappedCategories = data.map(category => ({
          ...category,
          label: category.name || 'Unknown',
          icon: categoryIcons[category.name || ''] || '🍽️',
        }));
        setCategories(mappedCategories);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryId: number | null, categoryName: string) => {
    if (categoryId !== null) {
      navigate("/DishesPage", { state: { Category_id: categoryId, name: categoryName } });
    } else {
      console.error("Category ID is null");
    }
  };

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 12,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200, // Large screens
        settings: {
          slidesToShow: 10,
        },
      },
      {
        breakpoint: 992, // Medium screens
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 768, // Small screens
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 576, // Extra small screens
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <Box sx={{ margin: '10px 0' }}>
      {loading ? (
        <Box sx={{ display: 'flex', gap: 2, overflowX: 'auto' }}>
          {Array.from(new Array(12)).map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              sx={{
                width: '80px',
                height: '100px',
                margin: '5px',
                borderRadius: '10px',
              }}
            />
          ))}
        </Box>
      ) : (
        <Slider {...settings}>
          {categories.map((category, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                gap: 2,
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
                border: '1px solid #eee',
                borderRadius: '10px',
                width: '80px',
                height: '100px',
                margin: '2px 5px', // Add horizontal space between items
                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                textAlign: 'center',
              }}
              onClick={() => handleCategoryClick(category.id, category.label)} 
            >
              <Typography variant="h4" sx={{ marginBottom: 1 }}>
                {category.icon}
              </Typography>
              <Typography variant="body2">{category.label}</Typography>
            </Box>
          ))}
        </Slider>
      )}
    </Box>
  );
};

export default CategorySection;