import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { Controller } from "react-hook-form";
import Delivery from '../../../../assets/Delivery.jpg';

interface DeliveryInformationProps {
  control: any;
  errors: any;
  watch: any;
  summary: any;
  setSummary: (updatedSummary: any) => void; // Callback to update the summary
}

const DeliveryInformation: React.FC<DeliveryInformationProps> = ({
  control,
  watch,
  summary,
  setSummary,
}) => {
  const apiKey = "AIzaSyAChe-VPZwDj5zzNSF7A9JHyf5MXgDcIEs";
  const [locationEnabled, setLocationEnabled] = useState(false);
  const [fetchedAddress, setFetchedAddress] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  // Synchronize summary with form values
  useEffect(() => {
    const subscription = watch((values: any) => {
      setSummary((prevSummary: any) => ({ ...prevSummary, ...values }));
    });

    return () => subscription.unsubscribe();
  }, [watch, setSummary]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocationEnabled(event.target.checked);

    if (event.target.checked && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          try {
            const address = await getAddressFromCoordinates(latitude, longitude);
            setFetchedAddress(address);
            setDialogOpen(true);
          } catch (error) {
            console.error("Error fetching address:", error);
          }
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    }
  };

  const getAddressFromCoordinates = async (
    latitude: number,
    longitude: number
  ): Promise<string> => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
        return data.results[0].formatted_address;
      } else if (data.status === "ZERO_RESULTS") {
        return "No address found for these coordinates";
      } else {
        throw new Error(`Error fetching address: ${data.status}`);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      return "Error fetching address";
    }
  };

  const handleDialogClose = (useAddress: boolean) => {
    setDialogOpen(false);

    if (useAddress) {
      setSummary((prev: any) => ({
        ...prev,
        deliveryAddress: fetchedAddress,
      }));
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", paddingTop: 20 }}>
      {/* Form Section */}
      <div style={{ flex: 1, padding: "2rem" }}>
        <h4 className="login-title">Delivery Information</h4>

        {/* Explanation Text */}
        <Typography variant="body1" style={{ marginBottom: "1rem", color: "#555" }}>
          This application requires location tracking to ensure accurate and timely delivery. 
          Please enable location tracking as a mandatory requirement.
        </Typography>

        <Typography variant="body1" style={{ marginBottom: "1rem", color: "#555" }}>
          Providing your location helps us ensure accurate and timely delivery.
          Your address will be used to calculate delivery times, avoid delays, and suggest food areas closer to you.
        </Typography>
        
        {/* Display Fetched Address */}
        {fetchedAddress && (
          <Typography variant="body2" style={{ marginTop: "1rem", color: "#555" }}>
            <strong>Detected Location:</strong> {fetchedAddress}
          </Typography>
        )}        

        {/* Use Location Tracking Checkbox */}
        <Controller
          name="locationEnabled"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  onChange={(e) => {
                    field.onChange(e); // Update the form value
                    handleCheckboxChange(e); // Perform the logic for location fetching
                  }}
                />
              }
              label="Allow Location Tracking"
            />
          )}
        />          	                               
      </div>

      {/* Image Section */}
      <div style={{ flex: 1, backgroundImage: `url('https://loremipsum.io/21-of-the-best-placeholder-image-generators')`, backgroundSize: "cover", backgroundPosition: "center", height: "100%" }}>
        <img
          src={Delivery}
          alt="Delivery"
          style={{ width: '100%', height: 'auto' }}
        />
      </div>

      {/* Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
        <DialogTitle>Confirm Delivery Address</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We have detected your current location as:
          </DialogContentText>
          <Typography variant="body1" style={{ marginTop: "1rem" }}>
            {fetchedAddress}
          </Typography>
          <DialogContentText style={{ marginTop: "1rem" }}>
            Would you like to use this as your delivery address?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="secondary">
            No
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeliveryInformation;