import React, { useEffect, useState } from "react";
import { fetchBusinessMenuPDF } from "../infrastructure/api/Business/BusinessAPI";
import { useParams } from "react-router-dom";
import { CircularProgress, Typography, Button } from "@mui/material";
import { Search } from "../domain/Types/Search";

const PDFMenus: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!slug) {
      setError("Invalid menu identifier.");
      return;
    }

    fetchMenuPDF(slug);
  }, [slug]);

  const fetchMenuPDF = async (slug: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const searchCriteria: Search = {
        slug,
        search: "",
        BusinessCategory: "",
        location: "",
        foodtype: "",
        minimumPrice: 0,
        maximumprice: 0,
      };

      const data = await fetchBusinessMenuPDF(searchCriteria);
      if (data?.menu_pdf_path) {
        const url = `https://app.foodhut.mw/Admin/public/images/${data.menu_pdf_path}`;
        setPdfUrl(url); // Set the PDF URL to display in iframe
      } else {
        setError("No menu found for the provided identifier.");
      }
    } catch (err) {
      console.error("Failed to fetch menu:", err);
      setError("An error occurred while fetching the menu.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = () => {
    setPdfUrl(null);
    setError(null);
    if (slug) fetchMenuPDF(slug);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
        padding: "20px",
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <>
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleRefresh}>
            Retry
          </Button>
        </>
      ) : pdfUrl ? (
        <>
        <iframe
          src={pdfUrl}
          title="Menu PDF"
          style={{
            width: "100%", // Makes the iframe fill the width of the browser
            height: "100%", // Makes the iframe fill the height of the browser
            border: "none", // Removes the iframe border
          }}
        ></iframe>
        <Button
        variant="contained"
        color="primary"
        style={{ position: "absolute", top: 10, right: 10 }}
        onClick={() => {
          const link = document.createElement("a");
          link.href = pdfUrl;
          link.download = "menu.pdf";
          link.click();
        }}
      >
        Download PDF
      </Button>
      </>
      ) : (
        <Typography variant="h6">Fetching the menu...</Typography>
      )}
    </div>
  );
};

export default PDFMenus;