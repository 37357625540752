import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import AppLayout from '../../customer/Components/Layouts/App/AppLayout';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { EmailVerification2FM, ResendCode } from '../../../infrastructure/api/User/UserAPI';
import { User2FM } from '../../../domain/Types/User2FM';

// Zod schema for validation
const emailVerificationSchema = z.object({
  verificationCode: z.string().nonempty('Please enter the verification code.'),
});

type EmailVerificationForm = z.infer<typeof emailVerificationSchema>;

type AdditionalFields = {
  emailSubmitted: boolean;
  isCodeVerified: boolean;
};

const EmailVerification: React.FC = () => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<EmailVerificationForm & AdditionalFields>({
    resolver: zodResolver(emailVerificationSchema),
    defaultValues: {
      verificationCode: '',
      emailSubmitted: true, // Email is already submitted at this point
      isCodeVerified: false,
    },
  });

  const [isVerifying, setIsVerifying] = useState(false); // Spinner state
  const [isResending, setIsResending] = useState(false); // Spinner state for resend button
  const isCodeVerified = watch('isCodeVerified');
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location.state || {}; // Retrieve email from the location state

  // Handle code verification
  const onVerifyCode = async (data: EmailVerificationForm) => {
    if (!email) {
      Swal.fire('Error', 'Email not found. Please go back and submit your email again.', 'error');
      return;
    }

    setIsVerifying(true); // Show spinner
    try {
      const verify: User2FM = {
        email: email, // Use `email` from location.state
        code: data.verificationCode,
      };
      const response = await EmailVerification2FM(verify); // Call the API
      setIsVerifying(false); // Hide spinner

      Swal.fire('Success', 'Email verified successfully!', 'success').then(() => {
        setValue('isCodeVerified', true); // Mark the form as verified
        navigate('/'); // Redirect to the homepage
      });
    } catch (error: any) {
      setIsVerifying(false); // Hide spinner
      Swal.fire('Error', error.response?.data?.message || 'An error occurred. Please try again.', 'error');
    }
  };

  // Handle resend verification code
  const onResendCode = async () => {
    const verify: User2FM = {
      email: email, // Use `email` from location.state
      code: "",
    };

    if (!email) {
      Swal.fire('Error', 'Email not found. Please go back and submit your email again.', 'error');
      return;
    }

    setIsResending(true); // Show spinner for resend button
    try {
      await ResendCode(verify); // Call the resend API
      setIsResending(false); // Hide spinner
      Swal.fire('Success', 'A new verification code has been sent to your email.', 'success');
    } catch (error: any) {
      setIsResending(false); // Hide spinner
    
      if (error.response?.status === 409) {
        // Show a notification when the email is already verified (not an error)
        Swal.fire('Notice', 'Your email is already verified. No need to resend the verification code.', 'info');
      } else {
        // Handle other errors (generic error message)
        Swal.fire('Error', error.response?.data?.message || 'An error occurred. Please try again.', 'error');
      }
    }    
  };

  return (
    <AppLayout>
      <Box className="email-verification-container" sx={{ p: 4, maxWidth: 400, margin: 'auto' }}>
        <Typography variant="h4" gutterBottom>
          Email Verification
        </Typography>
        <Typography variant="body1" gutterBottom>
          We sent a verification code to:
        </Typography>
        <Typography variant="h6" color="primary" gutterBottom>
          {email || 'your email'}
        </Typography>
        {!isCodeVerified ? (
          <>
            <form onSubmit={handleSubmit(onVerifyCode)}>
              <Box mb={2}>
                <Controller
                  name="verificationCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="Verification Code"
                      variant="outlined"
                      fullWidth
                      error={!!errors.verificationCode}
                      helperText={errors.verificationCode?.message}
                    />
                  )}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isVerifying} // Disable button when verifying
              >
                {isVerifying ? <CircularProgress size={24} /> : 'Verify Code'}
              </Button>
            </form>
            <Box mt={2}>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={onResendCode}
                disabled={isResending} // Disable button when resending
              >
                {isResending ? <CircularProgress size={24} /> : 'Resend Code'}
              </Button>
            </Box>
          </>
        ) : (
          <Typography variant="h6" color="success.main" align="center">
            Your email has been successfully verified!
          </Typography>
        )}
      </Box>
    </AppLayout>
  );
};

export default EmailVerification;