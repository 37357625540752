import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider, Button, Typography, Box } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MapIcon from '@mui/icons-material/Map';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SupportIcon from '@mui/icons-material/Support';
import BugReportIcon from '@mui/icons-material/BugReport';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { Logout } from '../../../../infrastructure/api/User/UserAPI';
const drawerWidth = 240;

const Navigation = () => {
  const win = window.sessionStorage;
  const navigate = useNavigate()

  const handleURLNavigate = (path: string) => {
    navigate(path);
  }  

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };  

  const ShowOrder = (orderType: string) => {
    navigate("/DriverOrders", { state: { orderType } });
  };   

  /*const handleLogout = () => {
    win.clear();
    navigate("/");
  };*/

  const handleLogout = async () => {
    try {
      // Clear local data
      win.clear(); // Ensure this function clears all relevant local data
  
      // Attempt to log out via API
      const data = await Logout();
      console.log(data);
      // Navigate to the login page or home page
      navigate("/");      
    } catch (error) {
      console.error("Logout failed:", error);      
    }
  };    

  return (
    <>
        {/* Modal Drawer */}
        <Drawer
  variant="temporary" // Makes the drawer modal-like
  open={drawerOpen}
  onClose={toggleDrawer} // Close the drawer when clicking outside
  ModalProps={{
    keepMounted: true, // Improve performance on mobile
  }}
  sx={{
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
    },
  }}
>
  {/* Drawer content */}
  <List>
    <ListItem button onClick={() => handleURLNavigate("/DriverHome")}>
      <ListItemIcon><DashboardIcon /></ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>

    {/* Orders Section */}
    <ListItem button onClick={() => ShowOrder("/DriverNewOrders")}>
      <ListItemIcon><AssignmentIcon /></ListItemIcon>
      <ListItemText primary="New Orders" />
    </ListItem>
    <ListItem button onClick={() => handleURLNavigate("/OrderInProgress")}>
      <ListItemIcon><HourglassEmptyIcon /></ListItemIcon>
      <ListItemText primary="Orders In Progress" />
    </ListItem>
    <ListItem button onClick={() => handleURLNavigate("/DriverOrderCompleted")}>
      <ListItemIcon><CheckCircleIcon /></ListItemIcon>
      <ListItemText primary="Orders Completed" />
    </ListItem>

    {/* Route and Map Section */}
    <ListItem button onClick={() => handleURLNavigate("/RouteOptimization")}>
      <ListItemIcon><MapIcon /></ListItemIcon>
      <ListItemText primary="Route Optimization" />
    </ListItem>
    <ListItem button onClick={() => handleURLNavigate("/LiveTracking")}>
      <ListItemIcon><LocationOnIcon /></ListItemIcon>
      <ListItemText primary="Live Tracking" />
    </ListItem>

    {/* Delivery Management Section */}
    <ListItem button onClick={() => handleURLNavigate("/ProofOfDelivery")}>
      <ListItemIcon><CameraAltIcon /></ListItemIcon>
      <ListItemText primary="Proof of Delivery" />
    </ListItem>
    <ListItem button onClick={() => handleURLNavigate("/FailedDeliveries")}>
      <ListItemIcon><ReportProblemIcon /></ListItemIcon>
      <ListItemText primary="Failed Deliveries" />
    </ListItem>

    {/* Support Section */}
    <ListItem button onClick={() => handleURLNavigate("/ContactSupport")}>
      <ListItemIcon><SupportIcon /></ListItemIcon>
      <ListItemText primary="Contact Support" />
    </ListItem>
    <ListItem button onClick={() => handleURLNavigate("/IncidentReport")}>
      <ListItemIcon><BugReportIcon /></ListItemIcon>
      <ListItemText primary="Incident Report" />
    </ListItem>

    {/* Earnings and Profile */}
    <ListItem button onClick={() => handleURLNavigate("/DriverEarnings")}>
      <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
      <ListItemText primary="Earnings" />
    </ListItem>
    <ListItem button onClick={() => handleURLNavigate("/DriverAccount")}>
      <ListItemIcon><AccountCircleIcon /></ListItemIcon>
      <ListItemText primary="My Account" />
    </ListItem>

    {/* Logout */}
    <ListItem
      button
      onClick={() => {
        win.clear(); // Clear session
        navigate("/"); // Redirect to sign-in
      }}
    >
      <ListItemIcon><LogoutIcon /></ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  </List>
</Drawer>


        <nav className="navbar navbar-expand-lg">
      <div className="container-fluid" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* Left section with Hamburger Menu and Brand */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Hamburger Menu */}
          <IconButton onClick={toggleDrawer} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          {/* Brand */}
          <Link className="navbar-brand" to="/Driverhome">FoodHut</Link>
        </Box>
        {/* Right section with Welcome text, Account link, and Logout button */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography>
            <span className="font-bold">Welcome:</span> {win.getItem("fname")} {win.getItem("lname")}
          </Typography>
          <Typography><span className="font-bold">|</span></Typography>
          <Button onClick={handleLogout}>Logout</Button>
        </Box>
      </div>
    </nav>
    </>
  )
}

export default Navigation