import { database, ref, set, push, onValue, messaging, getToken, onMessage, vapidKey } from "./firebaseConfig"

export const requestFCMToken = async (): Promise<string | null> => {
  try {
    // Request Notification Permission
    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      console.warn("Notification permission not granted.");
      return null;
    }

    // Get the FCM token
    const token = await getToken(messaging, { vapidKey });
    if (token) {
      console.log("FCM Token generated:", token);
      return token;
    } else {
      console.error("No registration token available.");
      return null;
    }
  } catch (error) {
    console.error("Error generating FCM token:", error);
    return null;
  }
};