import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { User } from "../../../domain/Types/User";
import { BASE_URL } from "../../../domain/Types/Config";
import { DeliveryDetails } from "../../../domain/Types/DeliveryDetails";
import { Payment } from "../../../domain/Types/Payment";
import { paymentoption } from "../../../domain/Types/paymentoption";
import { PasswordReset } from "../../../domain/Types/PasswordReset";
import { User2FM } from "../../../domain/Types/User2FM";

axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
  shouldResetTimeout: true // Reset timeout on retries
});

export const LoginUser = async (user: User): Promise<User> => {
  try {   
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return LoginUser(user); // Retry fetching data
    }

    const url = `${BASE_URL}/login`;
    const response: AxiosResponse<User> = await axios.post(
        url,{
            email:user.email,    
            password:user.password,
            rememberme:1,
            fcmtoken:user.device_token
          }

    );    
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


const api = axios.create({
  baseURL: BASE_URL,
});

// Add a global request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("Token"); // Retrieve token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const TokenAuthenticator = async (): Promise<{ message: string; user: User }> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection.");
      throw new Error("No internet connection");
    }

    // Make a sample authenticated request to verify the token
    const url = `${BASE_URL}/CheckUserAuth`; // Adjust the endpoint as per your backend
    const response: AxiosResponse<{ message: string; user: User }> = await api.get(url);

    // Log or return the response as needed
    console.log("Token is valid:", response.data);

    // Return the message and user data as required
    return {
      message: response.data.message,
      user: response.data.user
    };

  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Handle Axios-specific errors
      console.error("Axios error:", error.response?.data || error.message);

      if (error.response?.status === 401) {
        localStorage.removeItem("Token");
        console.log("Token invalid or expired. Please log in again.");
      }
    } else if (error instanceof Error) {
      // Handle generic errors
      console.error("Generic error:", error.message);
    } else {
      // Handle unexpected error shapes
      console.error("Unknown error:", error);
    }

    throw error; // Re-throw to allow further handling upstream
  }
};


export const RegisterUser = async (user: User): Promise<User> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return RegisterUser(user); // Retry fetching data
      }
      
      console.log(user);
      const url = `${BASE_URL}/useregistration`;
      const response: AxiosResponse<User> = await axios.post(url,user);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  //Resend Verification
  export const ResendCode = async (verification: User2FM): Promise<String> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return ResendCode(verification); // Retry fetching data
      }
      
      const url = `${BASE_URL}/regenerateVerificationCode`;
      const response: AxiosResponse<String> = await axios.post(url,verification);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  // Function to verify email with the backend API
  export const EmailVerification2FM = async (verify: User2FM): Promise<string> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log('No internet connection. Retrying...');
        return EmailVerification2FM(verify); // Retry fetching data
      }
  
      const url = `${BASE_URL}/verifyEmail`;
      const response: AxiosResponse<{ message: string }> = await axios.post(url, verify);
  
      // Return the success message from the response
      return response.data.message;
    } catch (error: any) {
      // Check if the error has a response with a status code
      if (axios.isAxiosError(error) && error.response) {
        // Handle specific error messages based on the backend response
        if (error.response.status === 400) {
          throw new Error('Invalid verification code. Please try again.');
        } else if (error.response.status === 404) {
          throw new Error('User not found.');
        }
      }
  
      // Generic error handling for network or unknown errors
      console.error('Error during email verification:', error);
      throw new Error('An unexpected error occurred. Please try again.');
    }
  };  

  export const UserForgotPassword = async (reset: PasswordReset): Promise<String> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return UserForgotPassword(reset); // Retry fetching data
      }
      
      console.log(reset);
      const url = `${BASE_URL}/forgotPassword`;
      const response: AxiosResponse<String> = await axios.post(url,reset);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  export const ResetMyPassword = async (reset: PasswordReset): Promise<String> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return ResetMyPassword(reset); // Retry fetching data
      }
      
      
      const url = `${BASE_URL}/resetPassword`;
      const response: AxiosResponse<String> = await axios.post(url,reset);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };  

  export const UpdateUser = async (user: User): Promise<User> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return UpdateUser(user); // Retry fetching data
      }
        
      const url = `${BASE_URL}/register`;
      const response: AxiosResponse<User> = await axios.post(url,user);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };  


  export const UpdatePaymentDetails = async (paymentopt: paymentoption): Promise<paymentoption> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return UpdatePaymentDetails(paymentopt); // Retry fetching data
      }
        
      const url = `${BASE_URL}/paymentoption`;
      const response: AxiosResponse<paymentoption> = await axios.post(url,paymentopt);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }; 

  export const UpdateDeliveryDetails = async (deliveryDetails: DeliveryDetails): Promise<DeliveryDetails> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return UpdateDeliveryDetails(deliveryDetails); // Retry fetching data
      }
        
      const url = `${BASE_URL}/SaveDeliveryDetails`;
      const response: AxiosResponse<DeliveryDetails> = await axios.post(url,deliveryDetails);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };  

  
export const ForgotUserPassword = async (): Promise<User[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return ForgotUserPassword(); // Retry fetching data
      }
  
      const url = `${BASE_URL}/paymentdetails`;
      const response: AxiosResponse<User[]> = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };


  export const Logout = async (): Promise<String> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return Logout(); // Retry fetching data
      }
  
      const url = `${BASE_URL}/logout`;
  
      // Include `withCredentials` as part of Axios config, not request body
      const response = await axios.post(
        url,
        {}, // Empty body for logout
        {
          withCredentials: true, // Ensures cookies are sent
        }
      );
  
      return response.data;
    } catch (error) {
      console.error("Error logging out:", error);
      throw error;
    }
  };  


  export const UserTwoFactorAuthentication = async (): Promise<User[]> => {
    try {
      // Check for internet connectivity
      const online = await isOnline();
      if (!online) {
        console.log("No internet connection. Retrying...");
        return UserTwoFactorAuthentication(); // Retry fetching data
      }
  
      const url = `${BASE_URL}/paymentdetails`;
      const response: AxiosResponse<User[]> = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };