import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import isOnline from 'is-online';
import { Business } from "../../../domain/Types/Business";
import { BASE_URL } from "../../../domain/Types/Config";
import { Search } from "../../../domain/Types/Search";
import { AddressBizDetailed } from "../../../domain/Types/AddressBizDetailed";
import { GeoSearch } from "../../../domain/Types/GeoSearch";

axiosRetry(axios, {
  retries: 3, // Number of retries
  retryDelay: axiosRetry.exponentialDelay, // Exponential backoff strategy
  shouldResetTimeout: true // Reset timeout on retries
});

export const fetchBusinessData = async (): Promise<Business[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchBusinessData(); // Retry fetching data
    }

    const url = `${BASE_URL}/Allbusinesses`;
    const response: AxiosResponse<Business[]> = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const fetchBusinessMenuPDF = async (searchCriteria: Search): Promise<Business> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchBusinessMenuPDF(searchCriteria); // Retry fetching data
    }

    const url = `${BASE_URL}/downloadMenu`;
    const response: AxiosResponse<Business> = await axios.post(url,searchCriteria);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchAllAddressedBusiness = async (): Promise<AddressBizDetailed[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchAllAddressedBusiness(); // Retry fetching data
    }

    const url = `${BASE_URL}/SearchAllBusinesses`;
    const response: AxiosResponse<AddressBizDetailed[]> = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const fetchAllAddressedBusinessbyCat = async (search: GeoSearch): Promise<AddressBizDetailed[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchAllAddressedBusinessbyCat(search); // Retry fetching data
    }

    const url = `${BASE_URL}/SearchAllBusinessesByCat`;
    const response: AxiosResponse<AddressBizDetailed[]> = await axios.post(url, search);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const fetchBusinessbyCategory = async (searchCriteria: Search): Promise<Business[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchBusinessData(); // Retry fetching data
    }

    const url = `${BASE_URL}/Businessbycategory`;
    const response: AxiosResponse<Business[]> = await axios.post(url, searchCriteria);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const SearchBar = async (searchCriteria: Search): Promise<Business[]> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return SearchBar(searchCriteria); // Retry fetching data
    }

    const url = `${BASE_URL}/SearchBar`;
    const response: AxiosResponse<Business[]> = await axios.post(url, searchCriteria);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const fetchBusinessbySlug = async (searchCriteria: Search): Promise<Business> => {
  try {
    // Check for internet connectivity
    const online = await isOnline();
    if (!online) {
      console.log("No internet connection. Retrying...");
      return fetchBusinessbySlug(searchCriteria); // Retry fetching data
    }

    console.log(searchCriteria);
    const url = `${BASE_URL}/SearchBusinessesBySlug`;
    const response: AxiosResponse<Business> = await axios.post(url, searchCriteria);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
